<template>
  <div class="container">
    <div class="content">
      <div class="item item_3">
        <div class="title">得分公式</div>
        <div class="detail">
          <div class="liuD">
            <div class="liuItem">
              <div class="liuChart" ref="chart1"></div>
              <div class="liuTitle">接待得分<br />(A)</div>
            </div>
            <div class="liuAlg">=</div>
            <div class="liuItem">
              <div class="liuChart" ref="chart2"></div>
              <div class="liuTitle">
                系统使用得分<br />B({{ scorePercent[0] }})
              </div>
            </div>
            <div class="liuAlg">+</div>
            <div class="liuItem">
              <div class="liuChart" ref="chart3"></div>
              <div class="liuTitle">展厅得分<br />C({{ scorePercent[1] }})</div>
            </div>
            <div class="liuAlg">+</div>
            <div class="liuItem">
              <div class="liuChart" ref="chart4"></div>
              <div class="liuTitle">网销得分<br />D({{ scorePercent[2] }})</div>
            </div>
          </div>
        </div>
      </div>
      <div class="item item_2">
        <div class="title">核心指标</div>
        <div class="detail">
          <div class="itemDetail" v-for="(item,index) in entryData" :key="item.label">
            <div class="label" :class="['icon_' + (index + 1)]">
              {{ item.label }}
            </div>
            <div class="desc">
              {{ item.value }}
            </div>
          </div>
        </div>
      </div>
      <div class="item item_4">
        <table class="table" cellspacing="0" cellspadding="0">
          <tbody v-for="(item,index) in listData" :key="item.id" @click="jumpToArea(item)">
            <tr style="background:rgba(4,32,137,0.5)">
              <th colspan="2" class="height27"></th>
              <th>接待得分</th>
              <th colspan="7">展厅得分</th>
            </tr>
            <tr>
              <th class="height41"></th>
              <th>排名</th>
              <th></th>
              <th>展厅<br />接待</th>
              <th>需求<br />分析</th>
              <th>产品<br />推介</th>
              <th>试乘<br />试驾</th>
              <th>购车<br />洽谈</th>
              <th>客户<br />送别</th>
              <th>一店<br />一模</th>
            </tr>
            <tr>
              <td rowspan="4">{{ item.name }}</td>
              <td rowspan="4">{{ index+1 }}</td>
             <td rowspan="4" ><span :style="getBgColor(item.total, 'none')">{{ item.total }}</span></td>
              <td class="height34" ><span :style="getBgColor(item.hall1)">{{ item.hall1 }}</span></td>
              <td><span :style="getBgColor(item.hall2)">{{ item.hall2 }}</span></td>
              <td><span :style="getBgColor(item.hall3)">{{ item.hall3 }}</span></td>
              <td><span :style="getBgColor(item.hall4)">{{ item.hall4 }}</span></td>
              <td><span :style="getBgColor(item.hall5)">{{ item.hall5 }}</span></td>
              <td><span :style="getBgColor(item.hall6)">{{ item.hall6 }}</span></td>
            </tr>
            <tr>
              <th colspan="5" class="height34">网销得分</th>
              <th colspan="2">系统使用得分</th>
            </tr>
            <tr>
              <th class="height41">开场<br />破冰</th>
              <th>产品<br />推介</th>
              <th>来店<br />邀约</th>
              <th>价格<br />应对</th>
              <th>告别<br />确认</th>
                 <td colspan="2" rowspan="2"><span :style="getBgColor(item.system)">{{ item.system }}</span></td>
            </tr>
            <tr>
            <td class="height34"><span :style="getBgColor(item.web1)">{{ item.web1 }}</span></td>
              <td><span :style="getBgColor(item.web2)">{{ item.web2 }}</span></td>
              <td><span :style="getBgColor(item.web3)">{{ item.web3 }}</span></td>
              <td><span :style="getBgColor(item.web4)">{{ item.web4 }}</span></td>
              <td><span :style="getBgColor(item.web5)">{{ item.web5 }}</span></td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import { postData } from "@/api/index";
import echarts from "echarts";
import echartsLiquidfill from "echarts-liquidfill";
import "../../../node_modules/echarts/map/js/china.js";
import nameMap from "@/utils/nameMap.js";
import { jump, getLightColor } from "@/utils/index";
export default {
  data() {
    return {
      entryData: [],
      listData: [],

      scoreData: [],
      scorePercent: {},
    };
  },
  mounted() {
    this.getDataBySearch();
    // this.liquidFill();
  },
  methods: {
    async getDataBySearch() {
      const query = { is_mobile:1,...this.$route.query, ...this.$store.state.query };
      this.loading = true;
      const { data = {} } = await postData("/api/board/region", query);
      console.log(data);
      const {
        list,
        title_hall,
        title_web,
        a,
        b,
        c,
        d,
        b_percent,
        c_percent,
        d_percent,
        core,
      } = data || {};
 
      this.entryData = [
      { label: "来店量", value: core.count },
        { label: "首次进店量", value: core.first },
        { label: "再次进店量", value: core.again },
        { label: "接待得分", value: core.total },
      ];

      this.listData = list;
 
      this.scorePercent = [b_percent, c_percent, d_percent];
     
      const liuS = [
        {
          value: a/100,
          ref: "chart1",
          color: "#75d3ff",
        },
        {
          value: b/100,
          ref: "chart2",
          color: "#a3ebff",
        },
        {
          value: c/100,
          ref: "chart3",
          color: "#30a891",
        },
        {
          value: d/100,
          ref: "chart4",
          color: "#4f9ab7",
        },
      ];

      this.liquidFill(liuS);
      this.loading = false;
    },
    liquidFill(liuS) {
      liuS.forEach((item) => {
        let liu = echarts.init(this.$refs[item.ref]);
        let option = {
          series: [
            {
              type: "liquidFill", //类型
              data: [item.value], //数据是个数组 数组的每一项的值是0-1
              outline: {
                //show: true , //是否显示轮廓 布尔值
                borderDistance: 4, //外部轮廓与图表的距离 数字
                itemStyle: {
                  borderColor: item.color, //边框的颜色
                  borderWidth: 4, //边框的宽度
                  shadowBlur: 0, //外部轮廓的阴影范围 一旦设置了内外都有阴影
                  shadowColor: "#000", //外部轮廓的阴影颜色
                },
              },
              backgroundStyle: {
                color: "transparent", //图表的背景颜色
              },
              itemStyle: {
                opacity: 1, //波浪的透明度
                shadowBlur: 10, //波浪的阴影范围
                shadowColor: item.color, //阴影颜色,
              },
              label: {
                fontSize: 16,
                   formatter: function (params) {
                        ;
                    return Math.floor(params.data*100);
                }
              },
              color: [item.color], //水波的颜色 对应的是data里面值
              shape: "circle", //水填充图的形状 circle默认圆形  rect圆角矩形  triangle三角形  diamond菱形  pin水滴状 arrow箭头状  还可以是svg的path
              //图表相对于盒子的位置 第一个是水平的位置 第二个是垂直的值 默认是[50%,50%]是在水平和垂直方向居中 可以设置百分比 也可以设置具体值
              radius: "90%", //图表的大小 值是圆的直径 可以是百分比 也可以是具体值 100%则占满整个盒子 默认是40%; 百分比下是根据宽高最小的一个为参照依据
              amplitude: 3, //振幅 是波浪的震荡幅度 可以取具体的值 也可以是百分比 百分比下是按图标的直径来算
              waveLength: "50%", //波的长度 可以是百分比也可以是具体的像素值  百分比下是相对于直径的 取得越大波浪的起伏越小
              phase: 0, //波的相位弧度 默认情况下是自动
            },
          ],
        };
        liu.setOption(option);
      });
    },
    initEcharts(dataValue) {
      this.myEchart = echarts.init(this.$refs.myEchart);

      let option = {
        tooltip: {
          show: false,
        },
        geo: {
          map: "china",
          roam: false, // 一定要关闭拖拽
          zoom: 1.23,
          center: [105, 36], // 调整地图位置
          label: {
            normal: {
              show: false, //关闭省份名展示
              fontSize: "10",
              color: "#fff",
            },
            emphasis: {
              show: true,
            },
          },
          itemStyle: {
            normal: {
              areaColor: "#0462fd",
              borderColor: "#0462fd",
              borderWidth: 1, //设置外层边框
              shadowBlur: 5,
              shadowOffsetY: 3,
              shadowOffsetX: 0,
              shadowColor: "#0462fd",
            },
          },
        },
        series: [
          {
            type: "map",
            map: "china",
            roam: false,
            zoom: 1.23,
            center: [105, 36],
            // geoIndex: 1,
            // aspectScale: 0.75, //长宽比
            showLegendSymbol: false, // 存在legend时显示
            label: {
              normal: {
                show: true,
                color: "#fff",
              },
              emphasis: {
                show: false,
              },
            },
            itemStyle: {
              normal: {
                areaColor: "#0d0059",
                borderColor: "#389dff",
                borderWidth: 0.5,
              },
              emphasis: {
                areaColor: "#17008d",
                shadowOffsetX: 0,
                shadowOffsetY: 0,
                shadowBlur: 5,
                borderWidth: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
          {
            name: "",
            type: "effectScatter",
            coordinateSystem: "geo",
            data: dataValue,

            symbol: "circle",
            symbolSize: 8,
            hoverSymbolSize: 10,
            tooltip: {
              formatter(value) {
                return value.data.name + "<br/>" + "设备数：" + "22";
              },
              show: true,
            },
            encode: {
              value: 2,
            },
            showEffectOn: "render",
            rippleEffect: {
              brushType: "stroke",

              period: 9,
              scale: 5,
            },
            hoverAnimation: true,
            label: {
              formatter: "{b}",
              position: "right",
              show: false,
            },
            itemStyle: {
              shadowBlur: 2,
              shadowColor: "#333",
            },
            zlevel: 1,
          },
        ],
      };

      this.myEchart.setOption(option);
    },
    getColor(v) {
      if (v < 60) {
        return "red";
      }
      if (v >= 80) {
        return "green";
      }
      return "yellow";
    },
     getBgColor(v, mode = null) {
      const { ft } = getLightColor(v);
      return `color:${ft}`;
    },
    jumpToArea(item) {
         let query={...this.$store.state.query,area_id:item.id};
      jump("/board/area",this, query);
    
    
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/index.scss";
.container {
  background: url("../../assets/board_bg2.png") no-repeat;

  background-size: cover;

  background-position: bottom center;
  .content {
    .item {
      display: flex;
      flex-direction: column;
      margin-top: 0.185185rem /* 10/54 */;
      &.item_1 {
        width: 9.666667rem /* 522/54 */;
        height: 7.907407rem /* 427/54 */;
        background: url("../../assets/battle_map.png");

        background-size: cover;
      }
      &.item_2 {
       width: 9.666667rem /* 522/54 */;
        height: 5.240741rem /* 283/54 */;
        background: url("../../assets/board.png");

        background-size: cover;
    
        .itemDetail{
          width: 50%;
         
        }
      }
      &.item_3 {
        width: 9.666667rem /* 522/54 */;
        height: 4.592593rem /* 248/54 */;
        background: url("../../assets/board_table.png");

        background-size: cover;
        .liuD {
          display: flex;
          justify-content: space-evenly;
          color: #fff;
          align-items: center;
          text-align: center;
          font-size: 0.277778rem /* 15/54 */;
          white-space: nowrap;
          .liuItem {
            .liuChart {
              width: 100%;
              height: 2.111111rem /* 114/54 */;
            }
            &:nth-child(1) {
              color: #75d3ff;
              width: 2.111111rem /* 114/54 */;
            }
            &:nth-child(3) {
              color: #a3ebff;
              width: 1.592593rem /* 86/54 */;
            }
            &:nth-child(5) {
              color: #30a891;
              width: 1.592593rem /* 86/54 */;
            }
            &:nth-child(7) {
              color: #4f9ab7;
              width: 1.592593rem /* 86/54 */;
            }
          }
        }
      }
      &.item_4 {
        width: 9.666667rem /* 522/54 */;
       border:1px solid #144cb9;
        box-shadow: 0px 0px 5px #144cb9 inset;
        color: #00d5e9;
        background-size: cover;
        font-size: 0.222222rem /* 12/54 */;

        th {
          max-width: 0.851852rem /* 46/54 */;
        }
      }

      .table {
        width: 100%;
        text-align: center;

        tr th,
        tr td {
          padding: 0px;
         border: thin solid #00116d;
          &.height27 {
            height: 0.518519rem /* 28/54 */;
          }
          &.height41 {
            height: 0.759259rem /* 41/54 */;
          }
          &.height34 {
            height: 0.666667rem /* 36/54 */;
          }
          &.height32 {
            height: 0.592593rem /* 32/54 */;
          }
        }
      }
      .title {
        margin-left: 0.481481rem /* 26/54 */;
        color: #00d5e9;
        font-size: 0.407407rem /* 22/54 */;
        height: 0.962963rem /* 52/54 */;

        line-height: 0.962963rem /* 52/54 */;
      }
     
    }
  }
}
</style>